import React, { useState, useEffect } from 'react';
import '../../Home/home.scss';
import Footer from '../../../Components/footer';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';

import TempleIcon from '../../../Assets/Images/E_store/TempleIcon.svg';
import moonIcon from '../../../Assets/Images/E_store/moonIcon.svg';
import goldIcon from '../../../Assets/Images/E_store/gold-Icon.svg';
import onlineshopIcon from '../../../Assets/Images/E_store/online-shop.svg';
import DonationRed from '../../../Assets/Images/E_store/DonationRed.svg';
import walk from '../../../Assets/Images/E_store/walk.svg';

import ladooIcon from '../../../Assets/Images/E_store/ladooIcon.svg';
import yagna from '../../../Assets/Images/E_store/yagna.svg';
import yagnaIcon from '../../../Assets/Images/E_store/yagnaIcon.svg';
import dhaja from '../../../Assets/Images/E_store/dhaja.svg';
import lakshmicharanpaduka from '../../../Assets/Images/E_store/lakshmicharanpaduka.svg';

import './onlineServices.scss';
import Arasuri from '../../../Components/arasuri';
import LazyLoader from '../../../Components/lazyLoader';

const OnlineServices = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  return (
    <>
      <Helmet>
        <title>
          Ambaji Mandir Online Booking Services | Online Money and Gold Donation
          | Online Prasad Order Booking
        </title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="online-se-banner online-banner">
            <div className="container">
              <h1>ઓનલાઇન સેવાઓ</h1>
            </div>
          </section>

          <section className="online-page">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/donation-money">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img
                          src={DonationRed}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>દાન - પૈસા</h5>
                      <p>
                        અંબાજી મંદિરને વિવિધ ડિજિટલ પેમેન્ટ પદ્ધતિઓ દ્વારા
                        ઓનલાઇન દાન કરવાની સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/donation-gold">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img
                          src={goldIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>દાન - સોનુ</h5>
                      <p>
                        મંદિરમાં દાન આપી મંદિરના સુવર્ણ શિખર કાર્યક્રમ મા ભાગ
                        લેવાનો અવસર મેળવો.
                      </p>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/online-ambaji-temple-prasad">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img
                          src={ladooIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>ઓનલાઈન પ્રસાદ</h5>
                      <p>
                        અંબાજી મંદિરનો પ્રસાદ ઓનલાઇન ઓર્ડર કરી તમારા ઘરે ડિલિવરી
                        મેળવો
                      </p>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  {/* <a href="#"> */}
                  <a href="/online-ambaji-temple-shop">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img
                          src={onlineshopIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>
                        ઓનલાઇન ખરીદી
                        {/* (ટૂંક સમયમાં આવી રહ્યું છે) */}
                      </h5>
                      <p>
                        અંબાજી મંદિરની વિવિધ ઉત્પાદનો અને મર્ચેન્ડાઇઝ ઓનલાઇન
                        સ્ટોર દ્વારા ખરીદો.
                      </p>
                    </div>
                  </a>
                </div>

                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/mandir-yagna-booking">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={yagnaIcon} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>મંદિર યજ્ઞ બુકિંગ</h5>
                      <p>
                        મંદિર માં યજ્ઞ બુકિંગ કરવા માટે ની ઓનલાઈન બુકિંગ સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="#">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={ladooIcon} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>અન્નકૂટ બુકિંગ (ટૂંક સમયમાં આવી રહ્યું છે)</h5>
                      <p>
                        અન્નકૂટ બૂકિંગ બુકિંગ કરવા માટે ની ઓનલાઈન બુકિંગ સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="#">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={lakshmicharanpaduka} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>પાવડી પૂજા બુકિંગ (ટૂંક સમયમાં આવી રહ્યું છે) </h5>
                      <p>
                        પાવડી પૂજા બુકિંગ કરવા માટે ની ઓનલાઈન બુકિંગ સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/51-shaktipeeth-parikrama-yagna-booking">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={yagna} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>
                        ૫૧ શક્તિપીઠ યજ્ઞ બુકિંગ (ટૂંક સમયમાં આવી રહ્યું છે)
                      </h5>
                      <p>
                        51 શક્તિપીઠ પરિક્રમા મોહોત્સવ દરમિયાન યજ્ઞમાં ભાગ લેવા
                        માટે ની ઓનલાઈન બુકિંગ સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/51-shaktipith-mahotsav">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={TempleIcon} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>પ૧ શક્તિપીઠ મહોત્સવ</h5>
                      <p>
                        51 શક્તિપીઠ મહોત્સવ માં ભાગ લેવા માટે ની ઓનલાઈન બુકિંગ
                        સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/gabbar-taleti-sang">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={walk} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>શક્તિપીઠ પરિક્રમા સંઘ રજિસ્ટ્રેશન</h5>
                      <p>
                        ગબ્બર શક્તિપીઠ પરિક્રમા સંઘ માં નોંધણી કરવા માટે ની
                        ઓનલાઈન બુકિંગ સુવિધા.
                      </p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <a href="/dhaja-booking">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img src={dhaja} alt="Ambaji Temple Banaskatha Gujarat"></img>
                      </div>
                      <h5>ધજા બુકિંગ (ટૂંક સમયમાં આવી રહ્યું છે) </h5>
                      <p>
                        અંબાજી મંદિર પર ધજા ચડવા માટે ની ઓનલાઈન બુકિંગ સુવિધા
                      </p>
                    </div>
                  </a>
                </div> */}

                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                 
                  <a href="/bhadarvi-poonam">
                    <div className="onlinebox">
                      <div className="online-icon">
                        <img
                          src={moonIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      
                      <h5>ભાદરવી પૂનમ રજિસ્ટ્રેશન</h5>
                      <p>
                        ભાદરવી પૂનમ સંઘ અને સેવા કેમ્પ માં ભાગ લેવા માટે ની
                        ઓનલાઈન બુકિંગ સુવિધા.
                      </p>
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default OnlineServices;
