import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getAll = () => {
  return http.get(api_urls?.getSchedule);
};

const getById = (id) => {
  return http.get(`${api_urls?.getSchedule}?id=${id}`);
};

const ScheduleService = {
  getAll,
  getById,
};

export default ScheduleService;
